import {
    Box,
    Button,
    ColumnLayout,
    FlashbarProps,
    Popover,
    SpaceBetween,
    StatusIndicator,
    StatusIndicatorProps
} from '@amzn/awsui-components-react';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { cancelEventTest, publishEventTest } from 'src/graphql/mutations';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import { GreenlightActionInput } from 'src/interfaces';
import { appBaseState } from 'src/stores/app';
import * as APIt from 'src/API';

interface EventCellTestElementProps {
    trackedEvent: string,
    eventStatus: StatusIndicatorProps.Type,
    eventText: string | JSX.Element,
    testStatus: StatusIndicatorProps.Type,
    testText: string | JSX.Element,
    isTesting: boolean,
    deviceID: string,
    deviceType: string | null | undefined,
    linkedDevice: string | undefined
}

/**
 * What actually renders if a device can be tested. This contains the 
 * button to begin or cancel a device test along with any logic for the API calls. 
 */
function EventCellTestElement({
    trackedEvent, 
    eventStatus, 
    eventText, 
    testStatus, 
    testText, 
    isTesting,
    deviceID,
    deviceType,
    linkedDevice
}: EventCellTestElementProps) {
    let { siteName } = useParams();

    const appState = useHookstate(appBaseState);
    const { dispatch } = useNotifications();
    const [testLoading, setTestLoading] = useState(false);

    const cancelTest = async () => {
        if (!siteName || !appState.user.value.username) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to submit cancellation',
                content: 'There was an error when trying to cancel a test. Try refreshing your page and resubmitting your request.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            return;
        }

        setTestLoading(true);

        const cancellation: GreenlightActionInput = {
            deviceID: deviceID,
            eventName: trackedEvent
        };

        try {
            const cancelReq = await API.graphql(
                graphqlOperation(cancelEventTest, {
                    input: {
                        siteName: siteName.toUpperCase(),
                        data: JSON.stringify({
                            submitter: appState.user.value.username,
                            tests: cancellation
                        })
                    }
                })
            ) as GraphQLResult<APIt.CancelEventTestMutation>;

            if (cancelReq.errors) {
                console.error(cancelReq.errors);

                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Unexpected cancellation response',
                    content: 'There was an unexpected response from AppSync when cancelling the test. Try refreshing the page and resubmitting your cancellation.'
                }
    
                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });
            }
        } catch (err) {
            console.error(err);

            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to submit cancellation request',
                content: 'There was an error submitting your cancellation request. Try refreshing the page and resubmitting your request.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });
        }

        setTestLoading(false);
    }

    const submitTest = async () => {
        if (!siteName || !appState.user.value.username) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Failed to submit event test',
                content: 'There was an error when trying to submit a test. Try resubmitting your request or refreshing the page.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            return;
        }

        setTestLoading(true);

        let test: GreenlightActionInput | GreenlightActionInput[] = {
            deviceID: deviceID,
            eventName: trackedEvent,
            testDuration: trackedEvent.toLowerCase().includes('held') ? 120 : 60
        }

        // For input devices, we submit a bulk request if there's a linked output
        if (deviceType && deviceType.includes('input') && linkedDevice) {
            test = [
                {
                    deviceID: deviceID,
                    eventName: 'Alarm Active'
                },
                {
                    deviceID: deviceID,
                    eventName: 'Alarm Canceled'
                },
                {
                    deviceID: linkedDevice,
                    eventName: 'Relay Contact Activated'
                },
                {
                    deviceID: linkedDevice,
                    eventName: 'Relay Contact Deactivated'
                },
            ]
        }

        try {
            const submitReq = await API.graphql(
                graphqlOperation(publishEventTest, {
                    input: {
                        siteName: siteName.toUpperCase(),
                        data: JSON.stringify({
                            submitter: appState.user.value.username,
                            tests: test
                        })
                    }
                })
            ) as GraphQLResult<APIt.PublishEventTestMutation>;

            if (submitReq.errors) {
                console.error(submitReq.errors);

                let message: FlashbarProps.MessageDefinition = {
                    type: 'error',
                    header: 'Unexpected response submitting test',
                    content: 'There was an unexpected response from AppSync when submitting the test. Try refreshing the page and resubmitting your test.'
                }
    
                dispatch({
                    type: NotificationActionType.ADD_NOTIFICATION,
                    message: message
                });
            }
        } catch (err) {
            console.error(err);

            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'Unexpected response submitting test',
                content: 'There was an unexpected response from AppSync when submitting the test. Try refreshing the page and resubmitting your test.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });
        }

        // A bit of a delay before the subscription picks up our data change.
        setTimeout(() => {
            setTestLoading(false);
        }, 3000);
    }

    return (
        <ColumnLayout columns={1} variant='text-grid'>
            <SpaceBetween size='xs'>
                <div>
                    <Box variant='awsui-key-label'>
                        <Popover
                            dismissButton={false}
                            size='small'
                            content={trackedEvent}
                        >
                            Event received
                        </Popover>
                    </Box>
                    <StatusIndicator type={eventStatus}>
                        {eventText}
                    </StatusIndicator>
                </div>
                <div>
                    <Box variant='awsui-key-label'>Last tested by</Box>
                    <StatusIndicator type={testStatus}>
                        {testText}
                    </StatusIndicator>
                </div>
                <div>
                        <Button
                            variant={isTesting ? 'normal' : 'primary'}
                            key={deviceID}
                            disabled={trackedEvent === 'Normal Exit REX' && isTesting ? true : false}
                            loading={testLoading}
                            onClick={() => isTesting ? cancelTest() : submitTest()}
                        >
                            {isTesting ? 'Cancel' : 'Test'}
                        </Button>
                </div>
            </SpaceBetween>
        </ColumnLayout>
    );
}

export default EventCellTestElement;