import { SelectProps } from '@amzn/awsui-components-react';
import { hookstate } from '@hookstate/core';
import { LANGUAGE_PREFERENCES } from 'src/constants';
import { EventsTableItem, ISectionEvent } from 'src/interfaces';
import { GreenlightViewPreferences } from 'src/types';

const getLanguage = (): SelectProps.Option => {
    const storedLang = localStorage.getItem('PREFERENCE_LANGUAGE');

    // Use stored preference
    if (storedLang) {
        try {
            const lang = LANGUAGE_PREFERENCES.find((l: SelectProps.Option) => l.value === storedLang);

            if (lang)
                return lang
        } catch {}
    }

    // Default
    return {
        value: 'en-US',
        label: 'English'
    }
}

export interface AppStateInterface {
    user: {
        username: string | undefined;
        userGroups: string[];
    };
    preferences: {
        view: GreenlightViewPreferences;
        language: SelectProps.Option;
    }
    stage: string;
    loadedSites: any[];
}

export const appBaseState = hookstate<AppStateInterface>({
    user: {
        username: undefined,
        userGroups: []
    },
    preferences: {
        view: localStorage.getItem("PREFERENCE_VIEW") as GreenlightViewPreferences ?? "TABS",
        language: getLanguage()
    },
    stage: 'beta',
    loadedSites: []
});

interface SiteDeviceStateInterface {
    loadedSiteDevices: EventsTableItem[];
    loadedProdDevices: number;
    loadedUCDevices: number;
    trackedEvents: ISectionEvent,
}

export const appEventsBaseState = hookstate<SiteDeviceStateInterface>({
    loadedSiteDevices: [],
    loadedProdDevices: 0,
    loadedUCDevices: 0,
    trackedEvents: {}
})