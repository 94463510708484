import React, { useEffect, useState } from 'react';
import { determineDeviceTrackedEventCellInfo, isEventApplicable } from "src/utils/trackedEventUtils";
import { Box } from '@amzn/awsui-components-react';
import { DeviceTrackedEventCellInfo } from 'src/interfaces';
import { EventCellProps } from 'src/types';
import EventCellTestElement from './EventCellTestElement';
import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator";
import EventCellOverridden from "src/components/SitePage/EventCellOverridden";

function EventCellInfo({
    deviceID,
    deviceName,
    deviceType,
    eventData,
    linkedDevice,
    readerConfig,
    trackedEvent,
}: EventCellProps) {
    const [isTesting, setTestState] = useState(false);
    const [eventStatus, setEventStatus] = useState<StatusIndicatorProps.Type>('stopped');
    const [testStatus, setTestStatus] = useState<StatusIndicatorProps.Type>('stopped');
    const [eventText, setEventText] = useState<string | JSX.Element>('Not tested');
    const [testText, setTestText] = useState<string | JSX.Element>('Not tested');

    // Sets the test and event states for the cell
    const setDeviceTrackedEventCellInfo = () => {
        const result: DeviceTrackedEventCellInfo = determineDeviceTrackedEventCellInfo(trackedEvent, eventData);
        setTestState(result.isTesting);
        setEventStatus(result.eventStatus);
        setTestStatus(result.testStatus);
        setEventText(result.eventText);
        setTestText(result.testText);
    }

    const EventCellNoTestElement = () => {
        return (
            <Box>
                <Box variant='strong'>
                    N/A
                </Box>
            </Box>
        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (eventData) {
                setDeviceTrackedEventCellInfo();
            }
        }, 1000);

        return () => clearTimeout(timer);
    });

    // Unrelated tests shown as N/A
    if (!isEventApplicable(trackedEvent, deviceName, deviceType, readerConfig)) {
        return <EventCellNoTestElement />
    }

    if (eventData.isOverridden) {
        return <EventCellOverridden testOverriddenBy={eventData.testOverriddenBy ?? 'Unknown'} />
    }

    return (
        <EventCellTestElement
            trackedEvent={trackedEvent}
            eventStatus={eventStatus}
            eventText={eventText}
            testStatus={testStatus}
            testText={testText}
            isTesting={isTesting}
            deviceID={deviceID}
            deviceType={deviceType}
            linkedDevice={linkedDevice}
        />
    )
}

export default EventCellInfo;