import React, { useEffect, useState } from 'react';
import { Box, Button, FormField, Modal, RadioGroup, Select, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { appBaseState } from 'src/stores/app';
import { useHookstate } from '@hookstate/core';
import { GreenlightViewPreferences } from 'src/types';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import { DEFAULT_USER_LOCALE, LANGUAGE_PREFERENCES } from 'src/constants';
import { LocalizationContextBuilder } from '@amzn/arb-tools';

interface UserPreferenceProps {
    isVisible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function UserPreferences({ isVisible, setVisible }: UserPreferenceProps) {
    const appState = useHookstate(appBaseState);

    const [preferredView, setPreferredView] = useState<GreenlightViewPreferences>(appState.preferences.view.value);
    const [language, setLanguage] = useState(appState.preferences.language.get({ noproxy: true }));
    const [bundle, isBundleLoading] = useBundle('components.UserPreferences');
    const { localizationContext, setLocalizationContext} = useLocalizationContext();

    const handleOkay = () => {
        // Store preferred view
        localStorage.setItem("PREFERENCE_VIEW", preferredView);
        appState.preferences.view.set(preferredView);

        // Rebuild and store language bundle if needed
        if (language != appState.preferences.language.get({ noproxy: true })) {
            localStorage.setItem('PREFERENCE_LANGUAGE', language.value ?? DEFAULT_USER_LOCALE);
            appState.preferences.language.set(language);

            const localizationContextBuilder = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_USER_LOCALE);
            setLocalizationContext(localizationContextBuilder.withLocale(language.value ?? DEFAULT_USER_LOCALE).build());
        }

        setVisible(false);
    }
    
    if (isBundleLoading) return (<Spinner size='large'/>);

    return (
        <Modal
            visible={isVisible}
            onDismiss={() => {
                setVisible(false);
            }}
            header={bundle.getMessage('user-preferences')}
            footer={
                <>
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                variant='link'
                                onClick={() => setVisible(false)}
                            >
                                {bundle.getMessage('cancel')}
                            </Button>
                            <Button 
                                variant='primary'
                                onClick={() => handleOkay()}
                            >
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                </>
            }
        >
            <SpaceBetween direction='vertical' size='s'>
                <FormField label={bundle.getMessage('language')}>
                    <Select
                        options={LANGUAGE_PREFERENCES}
                        selectedOption={language}
                        onChange={({ detail }) => setLanguage(detail.selectedOption)}
                    />
                </FormField>
                <FormField label={bundle.getMessage('preferred-view')}>
                    <RadioGroup
                        items={[
                            {
                                value: "TABS",
                                label: bundle.getMessage('tabs')
                            },
                            {
                                value: "SECTIONS",
                                label: bundle.getMessage('sections')
                            }
                        ]}
                        value={preferredView}
                        onChange={(ev) => setPreferredView(ev.detail.value as GreenlightViewPreferences)}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default UserPreferences;