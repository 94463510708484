import {ConnectionState} from "@aws-amplify/pubsub";
import React from 'react';
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import {useBundle} from "@amzn/react-arb-tools";

export interface IConnectionStatusProps {
    connectionState: ConnectionState
}

export default function ConnectionStatus(
    props: IConnectionStatusProps
) {
    const [bundle, isBundleLoading] = useBundle('components.SitePage.ConnectionStatus');

    const getStatusIndicatorType = () => {
        switch (props.connectionState) {
            case ConnectionState.Connected:
                return 'success';
            case ConnectionState.Disconnected:
                return 'error';
            default:
                return 'loading';
        }
    }

    const getStatusIndicatorColor = () => {
        switch (props.connectionState) {
            case ConnectionState.Connected:
                return 'green';
            case ConnectionState.Connecting:
                return 'blue';
            default:
                return 'red';
        }
    }

    const getStatusMessage = (bundle: {
        getMessage: (id: string) => string,
        formatMessage: (id: string, ...args: any) => string
    }): string => {
        switch (props.connectionState) {
            case ConnectionState.Connected:
                return bundle.getMessage('event-stream-connected');
            case ConnectionState.Connecting:
                return bundle.getMessage('subscribing-to-events');
            case ConnectionState.Disconnected:
                return bundle.getMessage('event-stream-disconnected');
            default:
                return bundle.getMessage('event-stream-interrupted');
        }
    }

    if (isBundleLoading) return (<Spinner size='normal'/>);

    return (
        <div style={{verticalAlign: 'middle'}}>
            <StatusIndicator
                type={getStatusIndicatorType()}
                colorOverride={getStatusIndicatorColor()}
            >
                {
                    getStatusMessage(bundle)
                }
            </StatusIndicator>
        </div>
    )
}