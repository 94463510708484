import { GreenlightEvent, PACSDevice } from 'src/interfaces';
import { Link, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { appEventsBaseState } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { useHookstate } from '@hookstate/core';

export default function SiteSum({siteDevices, siteEvents}: {
    siteDevices: PACSDevice[],
    siteEvents: GreenlightEvent[],
}) {
    let location = useLocation();
    let { siteName } = useParams();

    const [lastTestUser, setLastTester] = useState('-');
    const [lastTestTime, setLastTestTime] = useState('-');
    const [overriddenEventsCount, setOverriddenEventCount] = useState('-');
    const [passedEventsCount, setPassedEventCount] = useState('-');
    const [passedEventsPercent, setPassedEventsPercent] = useState('-');
    const [failedEventsCount, setFailedEventsCount] = useState('-');
    const [bundle, isBundleLoading] = useBundle('components.SitePage.SiteSummary');

    const appEventState = useHookstate(appEventsBaseState);

    const calculateTestNumbers = () => {
        const events = [...siteEvents];

        const failedEvents = events.filter(ev => (ev.eventReceivedSuccess == null || !ev.eventReceivedSuccess) && !ev.isOverridden);
        const overriddenEvents = events.filter(ev => ev.isOverridden);
        const passedEvents = events.filter(ev => ev.eventReceivedSuccess && !ev.isOverridden);
        const passedPercent = ((passedEvents.length / siteEvents.length) * 100).toFixed(1);

        setFailedEventsCount(`${failedEvents.length}`);
        setOverriddenEventCount(`${overriddenEvents.length}`);
        setPassedEventCount(`${passedEvents.length}`);
        setPassedEventsPercent(`${passedPercent}%`);
    }

    const findLastTest = () => {
        const events = [...siteEvents];

        const filteredEvents = events.filter(e => {
            return !e.isOverridden && e.testStartTimestampUTC !== undefined && e.testStartTimestampUTC !== null
        });

        filteredEvents.sort((a, b) => a.testStartTimestampUTC - b.testStartTimestampUTC);
        const lastEvent = filteredEvents.pop();
        if (lastEvent) {
            const lastTestTime = new Date(lastEvent.testStartTimestampUTC);

            setLastTester(lastEvent.testSubmittedBy ?? '-');
            setLastTestTime(lastTestTime.toLocaleString());
        }
    }

    useEffect(() => {
        if (siteEvents.length < 1)
            return;

        calculateTestNumbers();
        findLastTest();
    }, [location, siteEvents, siteDevices]);

    if (isBundleLoading) return (<Spinner size='large'/>);

    return (
        <Container
            header={
                <Header variant='h2'>
                    {bundle.getMessage('overview')}
                </Header>
            }
        >
            <ColumnLayout columns={3} variant='text-grid'>
                <SpaceBetween size='l'>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('site')}</Box>
                        <div>{`${siteName!.toUpperCase()}`}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('total-devices')}</Box>
                        <div>
                            {`${appEventState.loadedProdDevices.get() + appEventState.loadedUCDevices.get()} 
                            (${appEventState.loadedProdDevices.get()} prod | ${appEventState.loadedUCDevices.get()} uc)`}
                        </div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('total-tests')}</Box>
                        <div>{siteEvents.length}</div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size='l'>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('tests-passed')}</Box>
                        <div>{passedEventsCount}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('tests-failed')}</Box>
                        <div>{failedEventsCount}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('tests-overridden')}</Box>
                        <div>{overriddenEventsCount}</div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size='l'>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('percentage-passed')}</Box>
                        <div>{passedEventsPercent}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('last-test')}</Box>
                        <div>{lastTestTime}</div>
                    </div>
                    <div>
                        <Box variant='awsui-key-label'>{bundle.getMessage('last-tested-by')}</Box>
                        <div>
                            {lastTestUser == '-' ? '-' : (
                              <Link external href={`https://phonetool.amazon.com/users/${lastTestUser}`}>
                                  {lastTestUser}@
                              </Link>
                            )}
                        </div>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
}