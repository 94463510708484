import { ButtonDropdown, Spinner } from '@amzn/awsui-components-react';
import Header from '@amzn/awsui-components-react/polaris/header';
import React from 'react';
import { useBundle } from "@amzn/react-arb-tools";
import { useParams } from 'react-router-dom';

function PageHeader({exportSiteDevices}: {
  exportSiteDevices: Function
}) {
  let {siteName} = useParams();

  const [bundle, isBundleLoading] = useBundle('components.SitePage.PageHeader');

  const buttonDropdownClicked = (action: string) => {
    switch (action) {
      case 'export-failed':
        exportSiteDevices(true);
        break;
      case 'export-successful':
        exportSiteDevices(false);
        break;
      default:
        console.error(`Error: PageHeader() button dropdown item not handled: ${action}`);
    }
  }

  if (isBundleLoading) return (<Spinner size='large'/>);

  return (
    <Header
      variant='h1'
      actions={
        <ButtonDropdown
          variant='normal'
          items={[
            {id: 'export-successful',  text: bundle.getMessage("export-successful"), disabled: false},
            {id: 'export-failed', text: bundle.getMessage("export-failed"), disabled: false}
          ]}
          onItemClick={(ev) => buttonDropdownClicked(ev.detail.id)}
        >
          {bundle.getMessage("export-devices")}
        </ButtonDropdown>
      }
    >
      {siteName!.toUpperCase()}
    </Header>
  )
}

export default PageHeader;