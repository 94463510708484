import {
  Box,
  SpaceBetween,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import React from 'react';

interface EventCellOverriddenProps {
  testOverriddenBy: string
}

export default function EventCellOverridden({testOverriddenBy}: EventCellOverriddenProps) {

  return (
    <Box>
      <SpaceBetween direction='vertical' size='m'>
        <div>
          <Box variant='strong'>
            N/A
          </Box>
        </div>
        <div>
          <Box variant='awsui-key-label'>Test overridden by</Box>
          <StatusIndicator type='info'>
            {testOverriddenBy}
          </StatusIndicator>
        </div>
      </SpaceBetween>
    </Box>
  );
}