import React, { useEffect, useState } from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { State, useHookstate } from '@hookstate/core';
import { appBaseState, AppStateInterface } from 'src/stores/app';
import { useLocation } from 'react-router-dom';
import { NAV_LINKS } from 'src/constants';
import { useBundle } from '@amzn/react-arb-tools';
import UserPreferences from './UserPreferences';
import { Spinner } from '@amzn/awsui-components-react';

interface TopNavProps {
    setSiteSelectVisible: React.Dispatch<React.SetStateAction<boolean>>;
    appState: State<AppStateInterface>;
}

function TopNav({ setSiteSelectVisible, appState }: TopNavProps) {
    let location = useLocation();

    const i18nStrings = {
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu'
    };

    const appName = `Greenlight ${appState.stage.value === 'prod' ? '' : `:: ${appState.stage.value.toUpperCase()}`}`;
    const [site, setSite] = useState('');
    const [preferencesVisible, setPreferencesVisible] = useState(false);
    const [bundle, isBundleLoading] = useBundle('components.App');

    useEffect(() => {
        if (location.pathname.startsWith('/site/')) {
            setSite(location.pathname.split('/')[2].toUpperCase())
        } else {
            setSite('No site selected')
        }
    }, [location]);

    if (isBundleLoading) return (<Spinner size='large'/>);
    
    return (
        <div>
            <UserPreferences isVisible={preferencesVisible} setVisible={setPreferencesVisible} />
            <TopNavigation
                identity={{
                    href: '/',
                    title: appName
                }}
                i18nStrings={i18nStrings}
                utilities={[
                    // Site selection
                    {
                        type: 'button',
                        text: site,
                        ariaLabel: site,
                        iconSvg: <svg
                                    xmlns={"http://www.w3.org/2000/svg"}
                                    xmlnsXlink={"http://www.w3.org/1999/xlink"}
                                    xmlSpace={"preserve"}
                                    focusable={"false"}
                                    viewBox={"0 0 24 24"}
                                    x={"0"}
                                    y={"0"}
                                    key={"0"}
                                >
                                    <g>
                                        <path d="M14.039,22.804c0.12-0.022,0.238-0.047,0.356-0.073c0.218-0.049,0.433-0.105,0.646-0.166c0.11-0.031,0.22-0.06,0.328-0.095
                                            c0.267-0.086,0.529-0.182,0.787-0.288c0.092-0.038,0.183-0.077,0.273-0.117c0.238-0.105,0.472-0.217,0.701-0.338
                                            c0.126-0.067,0.248-0.14,0.372-0.212c0.151-0.088,0.3-0.177,0.447-0.271c0.134-0.087,0.266-0.176,0.397-0.268
                                            c0.125-0.089,0.247-0.181,0.368-0.275c0.135-0.104,0.27-0.208,0.4-0.318c0.095-0.081,0.184-0.167,0.276-0.251
                                            c0.288-0.262,0.562-0.538,0.822-0.83c0.05-0.056,0.102-0.109,0.151-0.166c0.138-0.162,0.268-0.331,0.397-0.501
                                            c0.057-0.076,0.115-0.15,0.17-0.227c0.126-0.176,0.247-0.355,0.363-0.538c0.044-0.07,0.086-0.141,0.129-0.212
                                            c0.12-0.199,0.236-0.398,0.343-0.605c0.002-0.004,0.004-0.008,0.006-0.012C22.551,15.53,23,13.819,23,12
                                            c0-1.944-0.509-3.767-1.393-5.351c-0.053-0.095-0.113-0.185-0.169-0.279c-0.149-0.249-0.304-0.493-0.472-0.728
                                            c-0.067-0.094-0.138-0.186-0.208-0.278c-0.178-0.234-0.364-0.461-0.56-0.68C20.134,4.612,20.07,4.54,20.004,4.47
                                            c-0.237-0.252-0.486-0.492-0.746-0.721c-0.03-0.027-0.059-0.055-0.089-0.081C17.243,2.009,14.741,1,12,1
                                            C9.458,1,7.124,1.871,5.261,3.319C5.256,3.323,5.25,3.327,5.245,3.331C5.038,3.493,4.838,3.663,4.643,3.839
                                            C4.598,3.879,4.553,3.92,4.509,3.961C4.334,4.124,4.164,4.292,4,4.466C3.942,4.527,3.886,4.591,3.83,4.653
                                            C3.678,4.822,3.529,4.991,3.388,5.169c-0.059,0.074-0.113,0.152-0.17,0.227C2.815,5.931,2.46,6.502,2.159,7.107
                                            C2.078,7.268,1.997,7.428,1.924,7.594C1.865,7.729,1.813,7.867,1.76,8.004C1.69,8.182,1.623,8.36,1.563,8.542
                                            C1.518,8.677,1.477,8.813,1.438,8.95C1.381,9.145,1.331,9.342,1.286,9.542C1.256,9.673,1.225,9.803,1.2,9.936
                                            c-0.043,0.223-0.075,0.449-0.103,0.677c-0.014,0.114-0.033,0.226-0.044,0.342C1.02,11.299,1,11.647,1,12c0,6.075,4.925,11,11,11
                                            c0.347,0,0.689-0.02,1.028-0.052c0.108-0.01,0.215-0.026,0.322-0.039C13.582,22.88,13.812,22.846,14.039,22.804z M22,12
                                            c0,1.003-0.152,1.97-0.428,2.883C20.615,14.064,20,12.861,20,11.5c0-1.23,0.503-2.329,1.306-3.134C21.748,9.494,22,10.717,22,12z
                                            M15,2.461v1.125c0,0.265-0.105,0.52-0.293,0.707L14,5v1h-1.586c-0.265,0-0.52,0.105-0.707,0.293l-1.414,1.414
                                            C10.105,7.895,10,8.149,10,8.414V10L9.447,8.894C9.2,8.4,8.6,8.2,8.106,8.447L7,9v1h1l1,1l0.707,0.707
                                            C9.895,11.895,10.149,12,10.414,12h2.243c0.222,0,0.438,0.074,0.613,0.21L17,15.104V16l-1.885,1.885
                                            c-0.076,0.076-0.139,0.164-0.187,0.26l-1.901,3.802C12.689,21.982,12.346,22,12,22l-0.707-0.707C11.105,21.105,11,20.851,11,20.586
                                            v-1.968c0-0.379-0.214-0.725-0.553-0.894l-0.894-0.447C9.214,17.107,9,16.761,9,16.382v-1.968c0-0.265,0.105-0.52,0.293-0.707
                                            c0.391-0.391,0.391-1.024,0-1.414l-0.178-0.178c-0.076-0.076-0.164-0.139-0.26-0.187l-1.799-0.9
                                            c-0.037-0.019-0.073-0.039-0.107-0.062L4.445,9.297C4.167,9.111,4,8.799,4,8.465V7.414c0-0.265,0.105-0.52,0.293-0.707l0.145-0.229
                                            c1.021-1.619,2.476-2.92,4.2-3.754L9.4,2.355C10.231,2.131,11.099,2,12,2C13.045,2,14.052,2.162,15,2.461z"
                                        />
                                    </g>
                                </svg>,
                        onClick: () => setSiteSelectVisible(true)
                    },
                    {
                        type: 'button',
                        text: bundle.getMessage('user-preferences'),
                        iconName: 'settings',
                        onClick: () => setPreferencesVisible(!preferencesVisible)
                    },
                    {
                        type: 'button',
                        text: bundle.getMessage('guardian-hub'),
                        ariaLabel: bundle.getMessage('guardian-hub'),
                        href: NAV_LINKS.GUARDIAN_HUB_URL,
                        external: true,
                        badge: false,
                        disableUtilityCollapse: true,
                    },
                    {
                        type: 'menu-dropdown',
                        text: bundle.getMessage('help'),
                        items: [
                            {
                                id: "contact",
                                text: bundle.getMessage('contact-us'),
                                href: NAV_LINKS.RIVER_WORKFLOW,
                                external: true,
                                externalIconAriaLabel: '(opens in new tab)'
                            },
                            {
                                id: "guide",
                                text: bundle.getMessage('user-guide'),
                                href: NAV_LINKS.GREENLIGHT_PUBLIC_WIKI,
                                external: true,
                                externalIconAriaLabel: '(opens in new tab)'
                            }
                        ]
                    },
                    {
                        type: 'button',
                        text: `${appState.user.username.value}@`,
                        iconName: "user-profile"
                    }
                ]}
            />
        </div>
    );
}

export default TopNav