import * as APIt from 'src/API';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import {
  Box,
  Button,
  FlashbarProps,
  FormField, Input,
  Modal,
  Multiselect,
  MultiselectProps,
  SpaceBetween
} from "@amzn/awsui-components-react";
import {EventsTableItem, GreenlightActionInput, GreenlightTestData, ISectionEvent} from "src/interfaces";
import {NotificationActionType, useNotifications} from 'src/hooks/notifications';
import React, {useEffect, useState} from 'react';
import {appBaseState, appEventsBaseState} from 'src/stores/app';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import {isEventApplicable} from "src/utils/trackedEventUtils";
import {useHookstate} from '@hookstate/core';
import {updateEventOverride} from "src/graphql/mutations";
import {useParams} from 'react-router-dom';

interface OverrideModalProps {
  item: EventsTableItem;
  sectionId: string;
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export function OverrideModal({item, sectionId, isVisible, setVisible}: OverrideModalProps) {
  const appEventState = useHookstate(appEventsBaseState);
  const appState = useHookstate(appBaseState);
  const {dispatch} = useNotifications();

  const [actionSubmitting, setActionSubmitting] = useState(false);
  const [initialOverriddenEvents, setInitialOverriddenEvents] = useState<MultiselectProps.Options>([]);
  const [selectedEvents, setSelectedEvents] = useState<MultiselectProps.Options>([]);
  const [eventOptions, setEventOptions] = useState<MultiselectProps.Option[]>([]);

  let {siteName} = useParams();

  useEffect(() => {
    const trackedEvents = appEventState.trackedEvents.get({
      noproxy: true
    }) as ISectionEvent;

    const filteredEvents = trackedEvents[sectionId].filter((event: string) => {
      return isEventApplicable(event, item.device_name, item.device_type, item.reader_config);
    });

    const events = filteredEvents.map((event: string) => ({
      label: event,
      value: event
    }));

    const currentlyOverridden = events.filter((event) => {
      // @ts-ignore
      const eventData: GreenlightTestData | null | undefined = item[`EVENT_${event.value.replace(/\s/g, '_')}`];
      return (eventData !== null && eventData!== undefined && eventData.isOverridden);
    });

    setEventOptions(events);
    setInitialOverriddenEvents(currentlyOverridden);
    setSelectedEvents(currentlyOverridden);
  }, []);

  const handleSubmit = async () => {
    if (!siteName || !appState.user.value.username) {
      let message: FlashbarProps.MessageDefinition = {
        type: 'error',
        header: 'Failed to submit override action(s)',
        content: 'There was an error when trying to submit the request. Try refreshing the page and resubmitting your request.'
      }

      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: message
      });

      setVisible(false);

      return;
    }

    setActionSubmitting(true);

    // Only update test events if selection has changed
    let data: GreenlightActionInput[] = [];
    eventOptions.forEach((event: MultiselectProps.Option) => {
      if (selectedEvents.includes(event)) {
        if (!initialOverriddenEvents.includes(event)) {
          data.push({
            deviceID: item.device_id,
            eventName: event.value!,
            isOverridden: true
          });
        }
      } else {
        if (initialOverriddenEvents.includes(event)) {
          data.push({
            deviceID: item.device_id,
            eventName: event.value!,
            isOverridden: false
          });
        }
      }
    });

    try {
      const submitReq = await API.graphql(
        graphqlOperation(updateEventOverride, {
          input: {
            siteName: siteName.toUpperCase(),
            data: JSON.stringify({
              submitter: appState.user.value.username,
              tests: data
            })
          }
        })
      ) as GraphQLResult<APIt.UpdateEventOverrideMutation>;

      if (submitReq.errors) {
        console.error(submitReq.errors);

        let message: FlashbarProps.MessageDefinition = {
          type: 'error',
          header: 'Unexpected response when submitting request',
          content: 'There was an unexpected response from our API when submitting the override request. Try resubmitting your request or refreshing the page.'
        }

        dispatch({
          type: NotificationActionType.ADD_NOTIFICATION,
          message: message
        });
      }
    } catch (err) {
      console.error(err);

      let message: FlashbarProps.MessageDefinition = {
        type: 'error',
        header: 'Failed to submit request',
        content: 'There was an issue submitting the override request. Try waiting a bit and resubmit your request or refresh the page.'
      }

      dispatch({
        type: NotificationActionType.ADD_NOTIFICATION,
        message: message
      });
    }

    setActionSubmitting(false);
    setVisible(false);
  }

  return (
    <Modal
      onDismiss={() => {
        if (actionSubmitting) {
          return;
        } else {
          setVisible(false);
        }
      }}
      visible={isVisible}
      header='Override N/A'
      size='large'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' loading={actionSubmitting} onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button variant='primary' onClick={() => handleSubmit()} loading={actionSubmitting}>
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction='vertical' size='l'>
        <FormField
          label='Device'
          description='Device to update'
        >
          <Input
            value={item.device_name}
            readOnly
          />
        </FormField>
        <FormField
          label="Events"
          description="Events to override and mark as N/A"
        >
          <Multiselect
            selectedOptions={selectedEvents}
            onChange={({detail}) => {
              setSelectedEvents(detail.selectedOptions);
            }}
            filteringType='auto'
            options={eventOptions}
            placeholder='Select events'
            tokenLimit={3}
            i18nStrings={{
              tokenLimitShowMore: "Show more selected events",
              tokenLimitShowFewer: "Show fewer selected events"
            }}
          />
        </FormField>
        <Alert
          header='Testing Override'
          statusIconAriaLabel='Warning'
          type='warning'
        >
          Overriding testing should only be used in worst case scenarios where a device is completely unable to be tested.
          If there is a recurring reason this is being done, please submit a Feature Request to automate Testing Override.
        </Alert>
      </SpaceBetween>
    </Modal>
  )
}