import { DeviceTrackedEventCellInfo, GreenlightTestData, ReaderConfigData } from "src/interfaces";
import { Link, Popover } from "@amzn/awsui-components-react";
import React from "react";

// Sets the test and event states for the cell
export const determineDeviceTrackedEventCellInfo = (
  trackedEvent: string,
  eventData: GreenlightTestData
): DeviceTrackedEventCellInfo => {
  const now: number = new Date().getTime();
  const elapsedTestTime: number = getElapsedTestTime(eventData, now);
  const result: DeviceTrackedEventCellInfo = {
    eventStatus: 'stopped',
    eventText: 'Not tested',
    isTesting: false,
    testStatus: 'stopped',
    testText: 'Not tested',
  };

  // REX events will not have test IDs and are treated slightly differently
  if (trackedEvent === 'Normal Exit REX') {
    if (eventData.lastTestEndTS > now && !eventData.rexTestFailed) { // Pending test :: Test timestamp is current
      result.isTesting = true;
      result.eventStatus = 'loading';
      result.testStatus = 'pending';
      result.eventText = `Waiting for event (${elapsedTestTime}s)`;
      result.testText = getTestCellInfo(eventData);
    } else if (eventData.lastEventReceived && eventData.lastTestEndTS < now) { // Successful test :: Test timestamp is < now and eventReceived == true
      result.isTesting = false;
      result.eventStatus = 'success';
      result.testStatus = 'success';
      result.eventText = 'REX test successful: No event received';
      result.testText = getTestCellInfo(eventData);
    } else if (eventData.rexTestFailed) { // Failed test :: REX test result is false
      result.isTesting = false;
      result.eventStatus = 'error';
      result.testStatus = 'error';
      result.eventText = 'An event was received during the test window';
      result.testText = getTestCellInfo(eventData);
    }
  } else {
    if (eventData.lastTestEndTS > now && !eventData.isCancelled && (eventData.lastTestID != eventData.lastSuccessID)) { // Active test :: Last test end timestamp > now, is not cancelled, and test IDs do not match
      result.isTesting = true;
      result.eventStatus = 'loading';
      result.testStatus = 'pending';
      result.eventText = `Waiting for event (${elapsedTestTime}s)`;
      result.testText = getTestCellInfo(eventData);
    } else if (eventData.lastSuccessID != eventData.lastTestID && eventData.lastSuccessID != null && !eventData.isCancelled) { // Latest test failed :: last success ID != last test ID, and was not cancelled
      result.isTesting = false;
      result.eventStatus = 'error';
      result.testStatus = 'error';
      result.eventText = getEventCellInfo(eventData, 'MISMATCH_TIMESTAMP');
      result.testText = getTestCellInfo(eventData, 'MISMATCH_TIMESTAMP');
    } else if (eventData.lastSuccessID == null && eventData.lastTestID != null) { // Failed, has never passed :: has never had a success, and has been tested
      result.isTesting = false;
      result.eventStatus = 'error';
      result.eventText = 'No event has been received';
      if (eventData.isCancelled) { // Last test was cancelled, but we still consider it tested
        result.testStatus = 'stopped';
        result.testText = getTestCellInfo(eventData, 'CANCELLED');
      } else { // Last test was not cancelled, so just a regular failure for the test status
        result.testStatus = 'error';
        result.testText = getTestCellInfo(eventData);
      }
    } else if (eventData.lastSuccessID == eventData.lastTestID && eventData.lastSuccessID != null && !eventData.isCancelled) { // Successful test :: last succes ID matches our last test ID, and was not cancelled
      result.isTesting = false;
      result.eventStatus = 'success';
      result.testStatus = 'success';
      result.eventText = getEventCellInfo(eventData);
      result.testText = getTestCellInfo(eventData);
    } else if (eventData.isCancelled && eventData.lastSuccessID != null) { // Cancelled tests
      result.isTesting = false;
      if (eventData.lastSuccessID == eventData.lastTestID) { // Last test was successful before the cancellation
        result.eventStatus = 'success';
        result.testStatus = 'stopped';
        result.eventText = getEventCellInfo(eventData);
        result.testText = getTestCellInfo(eventData, 'CANCELLED');
      } else { // Test was cancelled, but the test before was not successful, so fallback to a fail + cancelled state
        result.eventStatus = 'error';
        result.testStatus = 'error';
        result.eventText = getEventCellInfo(eventData, 'MISMATCH_TIMESTAMP');
        result.testText = getTestCellInfo(eventData, 'CANCELLED');
      }
    }
  }

  return result;
}

const getElapsedTestTime = (eventData: GreenlightTestData, now: number): number => {
  if (eventData.lastTestTS == null || eventData.lastTestEndTS < now || (eventData.lastSuccessID == eventData.lastTestID)) {
    return 0;
  }

  return Math.floor((now - eventData.lastTestTS) / 1000);
}

const getEventCellInfo = (eventData: GreenlightTestData, state: string | undefined = undefined) => {
  const eventTime = eventData.lastEventTS ? new Date(eventData.lastEventTS) : new Date();

  switch (state) {
    case "MISMATCH_TIMESTAMP": {
      return (
        `No event received during last test`
      )
    }

    default: {
      return (
        eventTime.toLocaleString()
      )
    }
  }
}

const getTestCellInfo = (eventData: GreenlightTestData, state: string | undefined = undefined): string | JSX.Element => {
  const testTime = eventData.lastTestTS ? new Date(eventData.lastTestTS) : new Date();

  switch (state) {
    case "MISMATCH_TIMESTAMP": {
      return (
        `${eventData.testSubmittedBy}@ ${testTime.toLocaleString()}`
      )
    }
    case "CANCELLED": {
      return (
        <Popover
          dismissButton={false}
          position='top'
          size='small'
          triggerType='text'
          content={
            <div>
              The last test was cancelled by <Link external
                                                   href={`https://phonetool.amazon.com/users/${eventData.testSubmittedBy}`}>{eventData.testSubmittedBy}@</Link>
            </div>
          }
        >
          {`${eventData.testSubmittedBy}@ ${testTime.toLocaleString()}`}
        </Popover>
      )
    }

    default: {
      return (
        `${eventData.testSubmittedBy}@ ${testTime.toLocaleString()}`
      )
    }
  }
}

export const isEventApplicable = (
  trackedEvent: string,
  deviceName: string,
  deviceType?: string | null,
  readerConfig?: ReaderConfigData,
): boolean => {
  // Hide unrelated tests for input devices
  if (deviceType && deviceType!.toLowerCase().includes('input') && !trackedEvent.includes('Alarm'))
    return false;

  // Hide unrelated tests for output devices
  if (deviceType && deviceType!.toLowerCase().includes('output') && !trackedEvent.includes('Relay'))
    return false;

  // Hide tests based on reader configuration
  if (trackedEvent.includes('REX') && readerConfig === undefined)
    return false;

  // S/L devices can ignore Door Held/Door Forced Alarm Tests
  if (deviceName.includes('S/L') && trackedEvent.includes('Door'))
    return false;

  if (readerConfig) {
    if (readerConfig.DONOTWAIT === 1 && trackedEvent === 'Access Granted No Entry Made')
      return false;

    if (Number(readerConfig.DOORCONTACT_SUPERVISION) < 2 && ['Door Forced Open', 'Door Held Open', 'Door Forced Open Canceled', 'Door Held Open Canceled'].includes(trackedEvent))
      return false;

    if (readerConfig.PAIRSLAVE === 1 && ['Door Held Open', 'Door Forced Open', 'Door Forced Open Canceled', 'Door Held Open Canceled'].includes(trackedEvent))
      return false;

    if ((readerConfig.PAIRMASTER === 1 || readerConfig.PAIRSLAVE === 1 || Number(readerConfig.REX_SUPERVISION) < 2) && trackedEvent.includes('REX'))
      return false;
  }

  return true;
}