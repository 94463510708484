import {
  Box,
  ButtonDropdown,
  CollectionPreferences,
  CollectionPreferencesProps,
  FlashbarProps,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Spinner,
  TableProps
} from '@amzn/awsui-components-react';
import { DEFAULT_PREFERENCES, PAGE_SIZE_OPTIONS } from './DeviceTablePreferences';
import { EventsTableItem, ISectionEvent } from 'src/interfaces';
import { NotificationActionType, useNotifications } from 'src/hooks/notifications';
import React, { ReactNode, useState } from 'react';
import { RelatedTable, useTreeCollection } from "@amzn/polaris-related-table";
import { exportDevicesCSV, exportFailedDevicesCSV } from 'src/utils/exportDevices';
import { BulkModal } from './BulkModal';
import { ConnectionState } from "@aws-amplify/pubsub";
import ConnectionStatus from "src/components/SitePage/ConnectionStatus";
import { appEventsBaseState } from "src/stores/app";
import styles from "./deviceTable.module.scss";
import { useBundle } from "@amzn/react-arb-tools";
import { useHookstate } from "@hookstate/core";
import { useParams } from 'react-router-dom';

interface EmptyTableStateProps {
    title: string;
    subtitle: string;
    action?: ReactNode;
}

function EmptyTableState({ title, subtitle, action }: EmptyTableStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export function DeviceTable({connectionState, tableItems, tableColumns, sectionHeader, tableVariant}: {
    connectionState: ConnectionState,
    tableItems: EventsTableItem[],
    tableColumns: any[],
    sectionHeader: string,
    tableVariant: TableProps.Variant
}) {
    const appEventState = useHookstate(appEventsBaseState);
    const [bulkTestVisible, setBulkTestVisible] = useState(false);
    const [bulkModalAction, setBulkModalAction] = useState('');
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

    const [bundle, isBundleLoading] = useBundle('components.SitePage.DeviceTable');

    const showBulkModal = (action: string) => {
        setBulkModalAction(action);
        setBulkTestVisible(true);
    }

    const { dispatch } = useNotifications();
    let { siteName } = useParams();

    const { expandNode, items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useTreeCollection(
        tableItems || [],
        {
            columnDefinitions: tableColumns,
            parentKeyPropertyName: "linked_input_id",
            keyPropertyName: "device_id",
            expandedPropertyName: "expanded",
            propertyFiltering: {
                filteringProperties: [
                    {
                        propertyLabel: "All Tests Passed",
                        key: 'tests_successful',
                        groupValuesLabel: 'Test Result Values',
                        operators: ['=', '!=']
                    },
                    {
                        propertyLabel: "Parent Device Name",
                        key: 'parent_device_name',
                        groupValuesLabel: 'Parent Device Name Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: "Device Name",
                        key: 'device_name',
                        groupValuesLabel: 'Device Name Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: "Device ID",
                        key: 'device_id',
                        groupValuesLabel: 'Device ID Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    {
                        propertyLabel: "Linked Device ID",
                        key: 'linked_device_id',
                        groupValuesLabel: 'Linked Device ID Values',
                        operators: [':', '!:', '=', '!=']
                    },
                    // Currently the only way of filtering a list; waiting for https://issues.amazon.com/issues/AWSUI-18334
                    {
                        propertyLabel: "Device Testers",
                        key: 'testers',
                        groupValuesLabel: 'Device Testers',
                        operators: [':', '!:']
                    },
                ],
                empty: (
                    <EmptyTableState
                        title="No devices"
                        subtitle="No devices to display."
                    />
                ),
                noMatch: (
                    <EmptyTableState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                ),
                defaultQuery: {
                    operation: "and",
                    tokens: [
                        {operator: ":", propertyKey: "device_name", value: "uc_"}
                    ]
                }
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {
              defaultState: {
                sortingColumn: tableColumns.find(column => (column.id === 'device_name'))
              }
            },
            selection: {
                keepSelection: true,
                trackBy: "device_id"
            }
        }
    );

    // ignore ref since RelatedTable does not support it
    const {ref, ...fcCollectionProps} = collectionProps;
    const selectedItems = collectionProps.selectedItems!;

    // Export devices for a specific section
    const exportSectionDevices = (action: string | null = null) => {
        // Only send uc_ devices. tableItems will only have items for the section table
        const ucItems: EventsTableItem[] = tableItems.filter(device => device.device_name && device.device_name.startsWith('uc_'));

        if (ucItems.length < 1) {
            let message: FlashbarProps.MessageDefinition = {
                type: 'error',
                header: 'No items to export',
                content: 'There are no available devices to export for this section. Only uc_ devices can be exported.'
            }

            dispatch({
                type: NotificationActionType.ADD_NOTIFICATION,
                message: message
            });

            return;
        }

        switch (action) {
            case 'export-failed':
                const trackedEvents = appEventState.trackedEvents.get({
                  noproxy: true
                }) as ISectionEvent;
                exportFailedDevicesCSV(siteName!, ucItems, trackedEvents, sectionHeader);
                break;
            case 'export-successful':
                exportDevicesCSV(siteName!, ucItems, sectionHeader);
                break;
            default:
                throw new Error(`Unsupported action: ${action}`);
        }
    }

    if (isBundleLoading) return (<Spinner size='large'/>);

    return (
        <div className={styles.deviceTable}>
            <RelatedTable
                {...fcCollectionProps}
                expandChildren={expandNode}
                expandColumnPosition={2}
                columnDefinitions={tableColumns}
                items={items}
                trackBy='device_id'
                stripedRows={preferences.stripedRows}
                wrapLines={preferences.wrapLines}
                sortingDisabled={false}
                resizableColumns={true}
                filter={
                    <SpaceBetween direction='vertical' size='xs'>
                        <ConnectionStatus connectionState={connectionState}/>
                        <PropertyFilter
                            i18nStrings={{
                                filteringAriaLabel: 'your choice',
                                dismissAriaLabel: 'Dismiss',
                                filteringPlaceholder: 'Search',
                                groupValuesText: 'Values',
                                groupPropertiesText: 'Properties',
                                operatorsText: 'Operators',
                                operationAndText: 'and',
                                operationOrText: 'or',
                                operatorLessText: 'Less than',
                                operatorLessOrEqualText: 'Less than or equal',
                                operatorGreaterText: 'Greater than',
                                operatorGreaterOrEqualText: 'Greater than or equal',
                                operatorContainsText: 'Contains',
                                operatorDoesNotContainText: 'Does not contain',
                                operatorEqualsText: 'Equals',
                                operatorDoesNotEqualText: 'Does not equal',
                                editTokenHeader: 'Edit filter',
                                propertyText: 'Property',
                                operatorText: 'Operator',
                                valueText: 'Value',
                                cancelActionText: 'Cancel',
                                applyActionText: 'Apply',
                                allPropertiesLabel: 'All properties',
                                tokenLimitShowMore: 'Show more',
                                tokenLimitShowFewer: 'Show fewer',
                                clearFiltersText: 'Clear filters',
                                removeTokenButtonAriaLabel: () => 'Remove token',
                                enteredTextLabel: text => `Use: "${text}"`,
                            }}
                            countText={`${filteredItemsCount} matches`}
                            {...propertyFilterProps}
                        />
                    </SpaceBetween>
                }
                header={
                    <Header
                        variant='h3'
                        counter={
                            tableItems && (
                                selectedItems.length ? `${selectedItems!.length} / ${tableItems.length} devices selected` : `${tableItems.length} devices`
                            )
                        }
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <ButtonDropdown
                                    variant='normal'
                                    items={[
                                      {id: 'export-successful',  text: bundle.getMessage('export-successful'), disabled: false},
                                      {id: 'export-failed', text: bundle.getMessage('export-failed'), disabled: false}
                                    ]}
                                    onItemClick={(ev) => exportSectionDevices(ev.detail.id)}
                                >
                                  {bundle.formatMessage('export-devices', {sectionHeader: sectionHeader})}
                                </ButtonDropdown>
                                <ButtonDropdown
                                    variant='primary'
                                    items={[
                                        {id: 'test', text: bundle.formatMessage('test-items', { itemCount: selectedItems.length}), disabled: false},
                                        {id: 'cancel', text: bundle.formatMessage('cancel-items', { itemCount: selectedItems.length}), disabled: false}
                                    ]}
                                    onItemClick={(ev) => showBulkModal(ev.detail.id)}
                                >
                                  {bundle.formatMessage('test-or-cancel', { itemCount: selectedItems.length})}
                                </ButtonDropdown>
                            </SpaceBetween>
                        }
                    />
                }
                pagination={
                    <Pagination {...paginationProps} />
                }
                variant={tableVariant}
                selectionType='multi'
                empty={
                    <EmptyTableState
                        title="No devices"
                        subtitle="There are no devices to display."
                    />
                }
                preferences={
                    <CollectionPreferences 
                        title="Table Preferences"
                        confirmLabel="Confirm"
                        cancelLabel="Cancel"
                        preferences={preferences}
                        onConfirm={({ detail }) => setPreferences(detail)}
                        pageSizePreference={{
                            title: 'Page size',
                            options: PAGE_SIZE_OPTIONS
                        }}
                        wrapLinesPreference={{
                            label: 'Wrap lines',
                            description: 'Select to wrap lines in the table'
                        }}
                        stripedRowsPreference={{
                            label: 'Stripe rows',
                            description: 'Select to highlight every other row in the table'
                        }}
                    />
                }
                stickyHeader
            />

            {bulkTestVisible ? (
                <BulkModal deviceItems={selectedItems.length ? selectedItems : (filteredItemsCount && filteredItemsCount == tableItems.length) ? tableItems : items} eventColumns={tableColumns} isVisible={bulkTestVisible} setVisible={setBulkTestVisible} action={bulkModalAction}/>
            ) : null}
        </div>
    );
}